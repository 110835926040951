<template>
	<div class="vx-row">
		<div class="vx-col w-full sm:w-2/2 lg:w-3/3 mb-base">
			<vx-card>
                <h2>Commentaire</h2>
                <vs-divider/>
                <br/>

                <div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Membre</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full text-primary cursor-pointer" v-on:click="viewMember(uid_member)">
						<span>{{ member }}</span>
					</div>
				</div>

                <div class="vx-row mb-6" >
					<div class="vx-col sm:w-1/3 w-full">
						<span>Date ajout</span>
					</div>
					<div class="vx-col sm:w-2/3 w-full">
						<span>{{ getDate(date) }}</span>
					</div>
				</div>
            </vx-card>
            <br/>
            <vx-card>{{commentary}}</vx-card>
        </div>
    </div>
</template>



<style lang="scss">
</style>

<script>
import Utils from '@/assets/utils/utils';
import Fdate from '@/assets/utils/fdate';
import Commentary from '@/database/models/commentary'
import Member from '@/database/models/member';

export default {
    data(){
        return{
            member     :'?',
            uid_member : null,
            date       : '',
            commentary : '?',
        }
	},
	mounted:function()
	{
		//let root = this;
        load(this);
    },
    watch:{
        $route (){
            load(this);
        }
    },
    methods:{
		getDate:function(date)
		{
			if(date)
				return Fdate.getDate( date )+' à '+Fdate.getHeure( date );
			return '?';
        },
        viewMember:function( uid )
		{
			if(uid)
                this.$router.push('/admin/member/'+uid);
		},
    }
};



function load(root)
{
    let uid_commentary = root.$route.params.uid;
    Commentary.getMemory( uid_commentary, function( commentaire )
    {
        root.date       = commentaire.createAt;
        root.commentary = commentaire.commentary;
        root.uid_member = commentaire.member;

        if(commentaire.member)
		Member.getMemory( commentaire.member, function(mbr)
		{
			root.member = Utils.StrCapital( mbr.first_name+" "+mbr.last_name );
        });
    });
}

</script>